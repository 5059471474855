import axios from 'axios'

export class Permissoes {
    static async cadastrar (payload) {
        return axios.post('permissao/add', {
            array: payload.permissoes,
            funcao_id: payload.funcao.id,
        });
    }
    static async alterar (payload) {
        return axios.put('permissao/edit', {
            array: payload.permissoes,
            funcao_id: payload.funcao.id,
        });
    }
    static async remover (id) {
        return axios.delete('/permissao/delete/'+id)
    }
    static async buscarALL () {
        return axios.get('/permissaos')
    }
    static async obtemUm (funcao_id) {
        return axios.get('/permissaos/'+funcao_id)
    }

    //permissao especial
    static async obtemUsuarioEspecialpermissao (login_id,funcao_id) {
      if(funcao_id){
        return axios.get('/permissao/especial/'+login_id+'/'+funcao_id)
      }
    }

    static async editarPermissaoUsuarioEspecial (tipo,payload) {
        return axios.put('/permissao/especial/tipo/'+tipo,payload)
    }



}
